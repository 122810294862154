import ListModelBase from "@/apps/core/models/listModelBase.js";

const modelName = "perawatanList";
const modelPath = "/perawatan/";

class PerawatanList extends ListModelBase {
  constructor() {
    let filterMap = { sub_departemen: null, verifikasi: null, cari: "" }
    super(modelName, modelPath, filterMap);
  }
}

export default PerawatanList;